import Key from '@mui/icons-material/Key';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, TextField, Typography, Zoom } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from '@/app/i18n/client';
import { supportEmailAddress } from '@/utils/config';

type Props = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  handleLoginWithSSO: () => Promise<void>;
  email: string;
  setEmail: (email: string) => void;
  loginWithSSOSelected: boolean;
  setLoginWithSSOSelected: (selected: boolean) => void;
  validEmail: boolean;
  lng: string;
};

export const LoginWithSSO: React.FC<Props> = ({
  loading,
  setLoading,
  handleLoginWithSSO,
  loginWithSSOSelected,
  setLoginWithSSOSelected,
  email,
  setEmail,
  validEmail,
  lng,
}) => {
  const loginButtonDisabled = !validEmail;

  const { t } = useTranslation(lng);

  return (
    <Grid
      style={{ display: 'flex', justifyContent: 'center' }}
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
    >
      <Box>
        <Zoom in>
          {loginWithSSOSelected ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <TextField
                sx={{ mb: 2 }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label="Email"
                name="email"
                placeholder="you@example.com"
                required
                size="medium"
                fullWidth
                focused
              />
              <LoadingButton
                disabled={loginButtonDisabled}
                loading={loading}
                loadingPosition="start"
                startIcon={<Key />}
                onClick={() => {
                  setLoading(true);
                  handleLoginWithSSO();
                }}
                fullWidth
                variant="contained"
                size="large"
              >
                {t('logInWithSSO').toUpperCase()}
              </LoadingButton>
              <Typography variant="caption">{t('setUpSSO') + ' ' + supportEmailAddress}</Typography>
              <Button startIcon={<ArrowBackIcon />} variant="text" onClick={() => setLoginWithSSOSelected(false)}>
                <Typography fontSize="small">{t('logInWithPassword')}</Typography>
              </Button>
            </Box>
          ) : (
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<Key />}
              onClick={() => setLoginWithSSOSelected(true)}
              fullWidth
              variant="contained"
              size="large"
            >
              {t('logInWithSSO').toUpperCase()}
            </LoadingButton>
          )}
        </Zoom>
      </Box>
    </Grid>
  );
};
